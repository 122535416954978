.modal-bg {
  @apply absolute inset-0 bg-gray-500 dark:bg-gray-700 opacity-75;
}

.modal {
  @apply inline-block align-bottom bg-white dark:bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md w-full sm:p-6;
}

.modal .modal-dismiss {
  @apply bg-white dark:bg-gray-900 rounded-md text-gray-400 dark:text-gray-500 dark:hover:text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.modal-title {
  @apply text-center text-lg leading-6 font-bold text-gray-900 dark:text-white mb-4;
}

.modal-body {
  @apply mt-2;
}

.modal-footer {
  @apply mt-5 pt-4 flex flex-1 space-x-3 items-center;
}