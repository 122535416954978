.calendar-events-wrap {
  @apply flex pt-3 overflow-x-auto relative;
}

.calendar-events-wrap .shadow-left {
  box-shadow: inset 3px 0 3px rgba(0,0,0,.175);
  /* box-shadow: inset 5px 0 12px -9px rgba(0,0,0,0.2); */
  @apply absolute left-0 bottom-0 w-4 h-full;
}

.calendar-events-wrap .shadow-right {
  box-shadow: inset -3px 0 3px rgba(0,0,0,.175);
  /* box-shadow: inset -5px 0 12px -9px rgba(0,0,0,0.2); */
  @apply absolute right-0 bottom-0 w-4 h-full;
}