@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

@layer base {
  body {
    @apply text-gray-700 dark:text-white;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-bold;
    @apply text-gray-900 dark:text-white;
  }

  h1 {
    @apply text-4xl;
  }

  h2 {
    @apply text-3xl;
  }
  
  h3 {
    @apply text-2xl;
  }

  a, button.link, .link {
    @apply text-indigo-600 hover:text-indigo-700 outline-none focus:outline-none cursor-pointer;
  }
}

@import './buttons.css';
@import './forms.css';
@import './navbar.css';
@import './card.css';
@import './sidePanel.css';
@import './modal.css';
@import './settings.css';
@import './chart.css';
@import './datePicker.css';
@import './calendar.css';
@import './tables.css';

.tag {
  @apply mr-2 mb-2 inline-flex items-center border border-transparent px-2 py-1 rounded text-sm font-medium bg-indigo-50 dark:bg-transparent text-indigo-800 dark:text-indigo-500 dark:border-indigo-500;
}
