.date-picker-wrapper {
  @apply absolute origin-top-left left-0 bg-white dark:bg-gray-900 shadow-md rounded z-10;
}

.DayPicker {
  @apply focus:outline-none;
}

.DayPicker-wrapper {
  @apply pb-0;
}

.DayPicker-Months {
  @apply focus:outline-none;
}

.DayPicker-Month {
  @apply m-3;
}

.DayPicker-Caption div {
  @apply text-base font-semibold;
}

.DayPicker-Day {
  @apply rounded-full p-1 text-sm focus:outline-none;
}

.DayPicker-Day.DayPicker-Day--today {
  @apply text-indigo-500;
}

.DayPicker:not(.DayPicker--interactionDisabled) 
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  @apply bg-indigo-50 dark:bg-gray-600;
}

.DayPicker-Weekday {
  @apply text-sm text-gray-400;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  @apply bg-indigo-500 text-white;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  @apply bg-indigo-500 text-white;
}