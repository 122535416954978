.settings-link {
  @apply text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-900 hover:text-gray-900 dark:hover:text-gray-300 flex items-center px-3 py-2 text-sm font-medium rounded-md;
}

.settings-link.active {
  @apply bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white hover:text-gray-900 dark:hover:text-white flex items-center px-3 py-2 text-sm font-medium rounded-md;
}

.settings-link .settings-link-icon {
  @apply text-gray-400 flex-shrink-0 -ml-1 mr-3 h-6 w-6;
}

.settings-link .settings-link-icon.active {
  @apply text-gray-500 dark:text-gray-400;
}

.settings-row {
  @apply flex justify-between items-center py-3;
}

.settings-section-header {
  @apply uppercase text-sm tracking-wide dark:text-gray-400 text-gray-500;
}

.settings-section-content {
  @apply divide-y divide-gray-200 dark:divide-gray-700;
}

.settings-helper-text {
  @apply text-xs text-gray-500;
}

