.side-panel {
  @apply h-full divide-y divide-gray-200 dark:divide-gray-700 flex flex-col bg-white dark:bg-gray-900 shadow-xl;
}

.side-panel-header-wrap {
  @apply py-6 px-4 sm:px-6 sticky top-0 bg-white dark:bg-gray-900;
}

.side-panel-header {
  @apply flex items-center justify-between;
}

.side-panel-header .side-panel-title {
  @apply text-xl font-semibold;
}

.side-panel-header .side-panel-header-action {
  @apply ml-3 h-7 flex items-center;
}

.side-panel-header .dismiss-btn {
  @apply rounded-md text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2;
}