.navbar {
  @apply bg-white border-b border-gray-200 dark:bg-black dark:border-gray-800;
}

.navbar-link {
  @apply border-transparent text-gray-500 dark:text-gray-400 hover:border-gray-300 dark:hover:border-gray-300 hover:text-gray-700 dark:hover:text-gray-100 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium;
}

.navbar-link.active {
  @apply border-indigo-500 text-gray-900 dark:text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium;
}

.dropdown-menu {
  @apply origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-900 z-50 ring-1 ring-black ring-opacity-5;
}

.dropdown-menu-item {
  @apply block px-4 py-2 text-sm text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 hover:bg-gray-100 hover:text-indigo-500 cursor-pointer;
}

.small-nav.menu-item {
  @apply border-transparent text-gray-600 dark:text-gray-300 dark:hover:bg-gray-800 hover:bg-gray-50 dark:hover:border-gray-700 hover:border-gray-300 dark:hover:text-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium;
}

.small-nav.menu-item.active {
  @apply bg-indigo-50 dark:bg-gray-900 dark:text-indigo-500 border-indigo-500 text-indigo-700;
}

.small-nav.sub-menu-item {
  @apply block px-4 py-2 text-base font-medium text-gray-500 dark:text-gray-400 hover:text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-300;
}

.page-sub-menu-wrap {
  @apply border-b border-gray-200 dark:border-gray-700;
}

.page-sub-menu {
  @apply -mb-px flex space-x-8;
}

.page-sub-menu .page-sub-menu-item {
  @apply border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-100 dark:hover:border-gray-300 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none;
}

.page-sub-menu .page-sub-menu-item.active {
  @apply border-indigo-500 text-indigo-600 dark:text-indigo-500;
}

.page-sub-menu .page-sub-menu-item .indicator-badge {
  @apply absolute top-2 inline-flex items-center px-1.5 py-0 text-xs rounded-full font-semibold bg-red-500 text-white;
  font-size: 9px;
}

/* Search */
input.nav-search {
  @apply block w-full bg-gray-100 dark:bg-gray-800 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 dark:text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white dark:focus:ring-black focus:border-white dark:focus:border-gray-900 sm:text-sm;
}

.nav-search-container {
  @apply flex-1 px-2 flex justify-center items-center lg:ml-6 lg:justify-end;
}

.nav-search-icon {
  @apply relative text-gray-400 focus-within:text-gray-600 dark:text-gray-600 dark:focus-within:text-gray-400;
}

.search-results-container {
  @apply origin-bottom-right divide-y divide-gray-200 dark:divide-gray-700 absolute right-0 top-12 mt-2 w-full rounded-md shadow-lg py-1 bg-white dark:bg-gray-900 z-10 ring-1 ring-black ring-opacity-5;
}

.search-result-item {
  @apply block text-sm text-gray-700 dark:text-gray-500 dark:hover:bg-gray-800 hover:bg-gray-100 hover:text-indigo-500 cursor-pointer;
}