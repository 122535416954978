input.text-field {
  @apply appearance-none block w-full px-3 py-2 border border-gray-300 dark:border-gray-800 rounded-md shadow-sm placeholder-gray-400 dark:placeholder-gray-300 dark:text-white dark:bg-gray-800 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500;
}

.input-attachment-right {
  @apply inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 text-gray-500 dark:text-gray-300;
}

input.text-field.with-attachment-right {
  @apply rounded-none rounded-l-md;
}

input.text-field.with-attachment-left {
  @apply rounded-none rounded-r-md;
}

textarea.text-area {
  @apply shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 dark:border-gray-800 rounded-md placeholder-gray-400 dark:placeholder-gray-300 dark:text-white dark:bg-gray-800;
}

select.select-input {
  @apply dark:bg-gray-800 dark:border-gray-800 block w-full pl-3 pr-10 py-2 border-gray-300 border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md;
}

label.form-field-label {
  @apply block font-semibold text-gray-700 dark:text-white;
}

.form-control {
  @apply grid grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-6;
}

.input-results-list {
  @apply mt-1 w-full rounded-md shadow-lg bg-white dark:bg-gray-800 z-10 ring-1 ring-black ring-opacity-5; /* py-1 */
}

.input-results-item {
  @apply block px-4 py-1 text-sm text-gray-700 dark:text-gray-300 dark:hover:bg-gray-700 hover:bg-gray-100 hover:text-indigo-400 cursor-pointer;
}

/* Slider */
.rc-slider-handle {
  @apply border-gray-200 dark:border-gray-700 border shadow-md hover:border-indigo-500 focus:outline-none focus:border-indigo-500 focus:ring-indigo-500;
}

.rc-slider-handle:active {
  box-shadow: none;
}

.rc-slider-rail {
  @apply bg-gray-200 dark:bg-gray-700;
}

.rc-slider-track {
  @apply bg-indigo-500;
}

.rc-slider-disabled {
  @apply bg-transparent;
}

.rc-slider.slider-neutral .rc-slider-track {
  @apply bg-gray-200 dark:bg-gray-700;
}

.rc-slider.slider-neutral .rc-slider-handle {
  @apply bg-indigo-500;
}