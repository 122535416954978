.table-wrap {
  @apply overflow-hidden border border-gray-200 dark:border-gray-700 sm:rounded;
}

table.table {
  @apply min-w-full divide-y divide-gray-200 dark:divide-gray-700;
}

thead.table-head {
  @apply bg-gray-50 dark:bg-gray-800;
}

th.th {
  @apply px-4 py-3 text-xs font-semibold;
}

tbody.table-body {
  @apply bg-white divide-y divide-gray-200 dark:bg-gray-900 dark:divide-gray-700;
}

td.td {
  @apply px-4 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white;
}