.btn {
  @apply group text-sm sm:text-base relative shadow-sm flex justify-center py-2 px-4 border border-gray-300 dark:border-gray-800 font-semibold rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}

.btn.btn-sm {
  @apply px-2.5 py-1.5 text-xs;
}

.btn:disabled {
  @apply opacity-75 cursor-not-allowed;
}

.btn.btn-block {
  @apply w-full;
}

.btn.btn-link {
  @apply w-auto p-0 border-none shadow-none outline-none focus:outline-none focus:ring-0 focus:ring-offset-0;
}

.btn.btn-primary {
  @apply bg-indigo-600 hover:bg-indigo-700 text-white;
}

.btn.btn-dark {
  @apply bg-black hover:bg-gray-900 dark:bg-gray-100 dark:text-black dark:hover:bg-gray-200 text-white;
}

.btn.btn-danger {
  @apply bg-red-600 mr-3 text-white;
}

.options-toggle-btn {
  @apply flex justify-center text-gray-600 dark:text-gray-300 sm:text-gray-700 sm:dark:text-white sm:text-base sm:relative sm:shadow-sm sm:py-2 sm:px-4 sm:border sm:border-gray-300 sm:dark:border-gray-800 sm:font-semibold sm:rounded-md sm:focus:outline-none sm:focus:ring-2 sm:focus:ring-offset-2 sm:focus:ring-indigo-500 bg-transparent rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500
}