.visx-group.visx-axis {
  @apply text-gray-500 dark:text-white;
}

.visx-group.visx-axis-tick {
  @apply text-gray-500 dark:text-white;
}

.visx-line.visx-axis-line {
  @apply text-gray-500 dark:text-white;
}

.visx-tooltip.visx-crosshair.visx-crosshair-vertical {
  @apply text-gray-300 dark:text-gray-400;
}

.chart-filter-button {
  min-width: fit-content;
  @apply flex flex-1 items-center px-3 py-2 text-sm cursor-pointer rounded;
}