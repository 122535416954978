.card {
  @apply bg-white dark:bg-gray-900 shadow-sm border border-gray-300 dark:border-gray-800 rounded-lg flex flex-col;
}

.card-title {
  @apply text-xl pt-3 px-3 sm:pt-4 sm:px-4;
}

.card-body {
  @apply p-3 sm:p-4 flex-1;
}

.card-footer {
  @apply bg-gray-50 border-t border-gray-100 dark:border-gray-700 dark:bg-gray-900 px-5 py-3 rounded-b-lg text-right justify-end flex items-center;
}